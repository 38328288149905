function makeVideosSameHeight() {
	// Căn độ cao video chính và các videos phụ bằng nhau trên desktop
	if ($(window).width() > 991) {
		$('.small-videos').height($('.big-video').height());
	} else {
		$('.small-videos').height('auto');
	}
}

$(document).ready(function($) {
	makeVideosSameHeight();

	$(window).on('resize', function(){
		makeVideosSameHeight();
	});
});