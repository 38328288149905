// $(document).ready(function($) {
// 	$.lockfixed("#sidebar_fixed",{offset: {top: 0, bottom: 700}});

// 	$(window).on('resize', function() {
// 		var s = $("#sidebar_fixed");
// 		var pos = s.position();
// 		var windowpos = $(window).scrollTop();

// 		if ($(window).width() < 576) {
// 			s.attr('style', 'position.static');
// 		}
// 	});
// });