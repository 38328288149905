function makeProductBoxSameHeight() {
	var highestBox = 0;
	var elementArr = [".name, .infos"];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.product-grid-item ' + value).height('auto');

		$('.product-grid-item ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
			$('.product-grid-item ' + value).height(highestBox);
		}
	});
}

$(document).ready(function($) {
	makeProductBoxSameHeight();

	$(window).on('load', 'resize', function(){
		makeProductBoxSameHeight();
	});
});