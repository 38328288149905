function makeStickyMainNav() {
	// if ($(window).width() > 576) {
		var s = $("#nav-menu");
		s.removeClass("stick");
		var pos = s.position();
		var windowpos = $(window).scrollTop();

		if (windowpos >= pos.top) {
			s.addClass("stick");
		} else {
			s.removeClass("stick");
		}
	// }
}

$(document).ready(function($) {
	$(window).on('load resize scroll', function() {
		makeStickyMainNav();
	});
});