$(document).ready(function() {
	$('#product-nav-tree .show-more').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$('#product-nav-tree > ul > li').toggleClass('showing-more');
	});

	$('#product-nav-tree-slideshow .show-more').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$('#product-nav-tree-slideshow > ul > li').toggleClass('showing-more');
	});

	$('#product-nav-tree-othersite .show-more').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$('#product-nav-tree-othersite > ul > li').toggleClass('showing-more');
	});
});