$(document).ready(function() {
	$('.submenu-caret-wrapper').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$(this).closest('li').toggleClass('open');
	});

	navMenu();
	$("#nav_menu button").click(function(){
		$("#product-nav-tree").removeClass('open');
	});
});

function navMenu() {
	w_window = windowSize();

	if(w_window[0]<768){
		$('#product-nav-tree .dropdown-menu .fa-angle-right').on('click', function(e){
			e.preventDefault();
			$("#bs-example-navbar-collapse-1").removeClass('in');
			$(this).toggleClass('fa-down');
			$(this).parent().parent().children(".dropdown-menu").toggleClass('mblock');
		});
	} else {
		$('#product-nav-tree').on('click', function (e){
			$(this).toggleClass('open');
		});
	}
}