function makeGalleryPhotoBoxSameHeight() {
	var highestBox = 0;
	var elementArr = ['.title', '.description'];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.item_photo ' + value).height('auto');

		$('.item_photo ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
			$('.item_photo ' + value).height(highestBox);
		}
	});
}

function makeVideoBoxSameHeight() {
	var highestBox = 0;
	var elementArr = ['.name', '.description'];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.item-video ' + value).height('auto');

		$('.item-video ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 576) {	// Chỉ căn bằng nhau trên desktop
			$('.item-video ' + value).height(highestBox);
		}
	});
}

$(document).ready(function($) {
	makeGalleryPhotoBoxSameHeight();
	makeVideoBoxSameHeight();

	$(window).on('load', 'resize', function(){
		makeGalleryPhotoBoxSameHeight();
		makeVideoBoxSameHeight();
	});
});